import React, { useState } from "react"; 
import Nav from '../Nav/Nav' 
import { read, utils, writeFile } from 'xlsx';
import { useNavigate } from "react-router-dom";
import Service  from "./service";
import Swal from 'sweetalert2';

const Home = () => {

    const navigate = useNavigate();
    const [data, setData] = useState([]);

    const handleImport = ($event) => {
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;
                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    setData(rows);
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }

    const handleExport = () => {
        Service.insert(data).then(response=>{
            Swal.fire({
            toast: true,
            title: "Données inseré avec succès",
            timer: 3000,
            icon: 'info',
        })
        }).catch(err=>{

        });
            
    }

    return (
        <>
        <div className="row">
            <div className="col-md-2">
                <Nav></Nav>
            </div>
             <div className="col-md-10">
                <div className="row mb-2 mt-5">
                    <div className="col-sm-6 offset-3">   
                        <div className="row">
                            <div className="col-md-6">
                                <div className="input-group">
                                    <div className="custom-file">
                                        <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange={handleImport}
                                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                                    </div>
                                    <button onClick={handleExport} className="btn btn-primary float-right">
                                    Import <i className="fa fa-download"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-6">
                                
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
        </>
        

    );
};

export default Home;
