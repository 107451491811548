import axios from "axios";
import backUrl from '../config/config.js'

export const ServiceBac = {
    getEvolutionStatistique : (data)=>{
        return axios.post(backUrl + `/api/bac/evolution/statistique`, data);
    },
    getData : (data) => {
        // console.log('hahahha', data);
        return axios.post(backUrl + `/api/bac/evolution`, data);
    },
    getAllBac: (data) => {
        // console.log('hahahha', data);
        return axios.post(backUrl + `/api/bac/`, data);
    },
    insert: (data) => {
        console.log('hahahha', data);
        return axios.post(backUrl + `/api/data/insert`, data);
    },
}

export default ServiceBac;